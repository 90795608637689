// normalize CSS across browsers
import "modern-normalize/modern-normalize.css"

import "@fontsource/roboto"

// Highlighting for code blocks
import "prismjs/themes/prism.css"
// custom CSS styles
import "./src/style.scss"

export const onRouteUpdate = props => {
  // TODO: ページを遷移した際に呼ぶ処理
}
